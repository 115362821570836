<template>
    <form action="" @submit.stop.prevent="() => {}">

      <div class="form-row desktop_form" v-if="device==='desktop'&&type === 'tabs'">
        <div class="form-group col-md-3">
          <price-plan-select
            @changeDestination="changed"
            type="dest"
            :options="availDestList"
          />
        </div>
        <div class="form-group col-md-3">
          <price-plan-select
            @changeDestination="changed"
            type="hotel"
            :options="availHotelList"
          />
        </div>
        <div class="form-group col-md-3" v-if="isFcAgentMarketerMode">
          <app-datepicker
            :availableDates="availableDates"
            @dates="chooseDate"
            type="date"
            :destinationText="destinationText"
          ></app-datepicker>
        </div>
        <div class="form-group col-md-3" v-else>
          <price-plan-multiple-select
            @changeDestination="changed"
            type="night"
            :options="availNightList"
          />
        </div>
        <div class="form-group col-md-3 d-flex" style="height:3rem;">
          <button
            class="btn flex-fill"
            @click="submitSearch"
            :disabled="searchDisable"
          >
              {{$t('search-tab.search')}}
            <b-spinner v-if="isLoading"></b-spinner>
            <i class="fas fa-search ml-3" v-else></i>
          </button>
        </div>
      </div>

      <!-- <MobileSearchForm/> -->
      <div class="card card-body boxSideSearch border-0" v-if="device==='mobile'" :class="{ mobile_form: type === 'tabs' }">
        <div class="form-row">
          <div class="form-group col-12">
            <price-plan-select
              @changeDestination="changed"
              type="dest"
              :options="availDestList"
            />
          </div>
          <div class="form-group col-12">
            <price-plan-select
              @changeDestination="changed"
              type="hotel"
              :options="availHotelList"
            />
          </div>
          <div class="form-group col-12" v-if="isFcAgentMarketerMode">
            <app-datepicker
              :availableDates="availableDates"
              @dates="chooseDate"
              type="side"
              :destinationText="destinationText"
            ></app-datepicker>
          </div>
          <div class="form-group col-12" v-else>
            <price-plan-multiple-select
              @changeDestination="changed"
              type="night"
              :options="availNightList"
            />
          </div>
          <div class="form-group d-flex">
            <button
              class="btn flex-fill btn-outline-dark btn-search mobile"
              @click="submitSearch"
              :disabled="searchDisable"
            >
              {{$t('search-tab.search')}}
              <b-spinner v-if="isLoading" small></b-spinner>
              <i class="fas fa-search" v-else></i>
            </button>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  // BFormSelect,
  // BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import dayjs from 'dayjs';

export default {
  name: 'SearchTab',
  components: {
    PricePlanSelect: () => import('@/components/pricePlan/PricePlanSelect'),
    PricePlanMultipleSelect: () => import('@/components/pricePlan/PricePlanMultipleSelect'),
    AppDatepicker: () => import('@/components/atoms/CustomDatepicker'),
    // BFormSelect,
    // BFormSelectOption,
    BSpinner,
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    category: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      calendarData: 'GET_CALENDAR_DATA',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      availDests: 'GET_AVAILABLE_DESTINATION_FOR_MARKETER',
      availHotels: 'GET_AVAILABLE_HOTELS_FOR_MARKETER',
      availNights: 'GET_AVAILABLE_NIGHTS_FOR_MARKETER',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    searchDisable() {
      return !(this.selectedDest && this.selectedHotelId && this.selectedNights);
    },
    availDestList() {
      const { availDests } = this;
      if (!availDests) return [];
      return availDests[this.category.code];
    },
    availHotelList() {
      const { availHotels } = this;
      if (!availHotels) return [];
      return availHotels[this.category.code];
    },
    availNightList() {
      const { availNights } = this;
      if (!availNights) return [];
      return availNights[this.category.code]?.sort((a, b) => a - b) || [];
    },
    availableDates() {
      const { calendarData } = this;
      if (!calendarData) return [];
      return [...this.calendarData];
    },
    destinationText() {
      const { destination, lang } = this;
      if (!destination) return '';

      const destinationList = this.category.destinations;
      const currentItem = destinationList.find((dest) => dest.code === destination);
      return `${currentItem.name[lang]}`;
    },
  },
  data() {
    return {
      selectedDest: null,
      selectedHotelId: null,
      selectedNights: null,
      queryBody: {
        marketerId: this.$route.query.marketerId,
        category: this.category.code,
      },
      packStartDate: null,
      destination: '',
    };
  },
  created() {
    // this.$store.dispatch('FETCH_AVAILABLE_DESTINATION_FOR_MARKETER');
  },
  methods: {
    applyRoute() {
      let paramFcAgentMode = '';
      if (this.selectedDest) this.queryBody.dest = this.selectedDest;
      if (this.selectedHotelId) this.queryBody.hotelId = this.selectedHotelId;
      if (this.selectedNights) this.queryBody.nights = this.selectedNights;
      if (this.packStartDate) this.queryBody.packStartDate = this.packStartDate;

      if (this.isFcAgentMarketerMode) paramFcAgentMode = '&fc-agent-mode';

      this.$store.commit('SET_SEARCH_INFORMATION', this.queryBody);

      this.$router.push(`${this.$route.path}?${new URLSearchParams(this.queryBody).toString()}${paramFcAgentMode}`);
    },
    submitSearch() {
      if (this.searchDisable) return;
      this.$store.dispatch('FETCH_SELECTED_DEALS_FOR_MARKETER');
    },
    changed(item) {
      if (!item.selected) return;

      switch (item.type) {
        case 'dest':
          this.selectedDest = item.selected.value;

          this.destChanged(this.selectedDest);

          this.$store.dispatch('FETCH_AVAILABLE_HOTELS_FOR_MARKETER', this.selectedDest);
          this.selectedHotelId = null;
          this.selectedNights = null;
          this.$children.find((child) => (child.type === 'hotel')).resetData();
          if (!this.isFcAgentMarketerMode) {
            this.$children.find((child) => (child.type === 'night')).resetData();
          }
          break;
        case 'hotel':
          this.selectedHotelId = item.selected.value;
          this.$store.dispatch('FETCH_AVAILABLE_NIGHTS_FOR_MARKETER', { dest: this.selectedDest, hotelId: this.selectedHotelId });
          this.selectedNights = null;
          if (!this.isFcAgentMarketerMode) {
            this.$children.find((child) => (child.type === 'night')).resetData();
          }
          break;
        case 'night':
          this.selectedNights = item.selected.map((itm) => (itm.value));
          break;
        default:
          break;
      }
      this.applyRoute();
    },
    async destChanged(dest) {
      this.destination = dest;

      const categorySubjectIdList = this.category.subjectCodes.map(
        (item) => item,
      );

      await this.$store.dispatch('FETCH_CALENDAR_DATA', { dest, subjects: categorySubjectIdList });

      return true;
    },
    chooseDate(date) {
      this.packStartDate = date.from;
      if (date.from && date.to) {
        this.selectedNights = [dayjs(date.to).diff(dayjs(date.from), 'day')];
        this.applyRoute();
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
  .btn-search{
    padding-right: 20px;
  }
}
.custom-control {
  padding-right: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
</style>
